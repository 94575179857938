<template>
  <CRow>
    <CCol col="12">
      <CModal :show.sync="showModalCRUD" :centered="true" :title="$t(current_crud.title)" size="lg">
        <template v-if="showModalCRUD">
          <ModalCRUD :options="current_crud" @modalUpdated="onModalUpdated" />
        </template>
      </CModal>

      <CCard no-header :accentColor="isFormDirty ? (isFormValid ? 'success' : 'danger') : ''">
        <CCardBody>
          <h3>{{ form.id ? $t('Edit provider') + ` id: ${form.id}` : $t('Create provider') }}</h3>
          <CForm autocomplete="off" @keydown="clearErrors($event && $event.target ? $event.target.name : 'test')">
            <ACard>
              <AMultiSelectMaster name="provider_sector_id" label="Sector" v-model="form.provider_sector_id" :options="lists.provider_sectors" :isValid="isValid('provider_sector_id')" :errors="getErrors('provider_sector_id')" @addElement="onAddElement('provider_sector')" />
            </ACard>

            <ACard>
              <AInput :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }" name="name" label="Name" v-model="form.name" :isValid="isValid('name')" :errors="getErrors('name')" />
              <AInput :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }" name="identification" label="Identification" v-model="form.identification" :isValid="isValid('identification')" :errors="getErrors('identification')" />
              <AInput name="address" label="Address" v-model="form.address" :isValid="isValid('address')" :errors="getErrors('address')" />
              <AInput :horizontal="{ label: 'col-sm-3', input: 'col-sm-6' }" name="phone" label="Phone" v-model="form.phone" :isValid="isValid('phone')" :errors="getErrors('phone')" />
            </ACard>

            <ACard>
              <AMultiSelect name="provider_payment_method_id" label="Payment method" v-model="form.provider_payment_method_id" :options="lists.provider_payment_methods" :isValid="isValid('provider_payment_method_id')" :errors="getErrors('provider_payment_method_id')" @select="value => onPaymentMethodChanged(value)" />
              <template v-if="requires_bank">
                <AMultiSelect name="bank_id" label="Bank" v-model="form.bank_id" :options="lists.banks" :isValid="isValid('bank_id')" :errors="getErrors('bank_id')" />
                <AMultiSelect name="account_type_id" label="Account type" v-model="form.account_type_id" :options="lists.account_types" :isValid="isValid('account_type_id')" :errors="getErrors('account_type_id')" />
                <AInput name="account_number" label="Account number" v-model="form.account_number" :isValid="isValid('account_number')" :errors="getErrors('account_number')" />
              </template>
            </ACard>
          </CForm>
          <CCardFooter>
            <CButton class="mr-2" :color="form.id ? 'info' : 'primary'" adisabled="!isFormValid" @click="submit">{{ $t(form.id ? 'Save' : 'Create') }}</CButton>
            <CButton color="secondary" @click="goBack">{{ $t('Back') }}</CButton>
          </CCardFooter>
        </CCardBody>
      </CCard>
    </CCol>

    <!-- // TODO: Debug view... create a global component -->
    <CCol v-if="DEBUG" col="12">
      <pre>{{ form }}</pre>
      <div class="summary text-red" v-if="$v.form.$error">
        Form has errors
        <pre>{{ showErrors }}</pre>
      </div>
    </CCol>
  </CRow>
</template>

<script>
import formMixin from '@/app/views/_mixins/form-mixin'

import { DEBUG } from '@/config/config'

export default {
  name: 'ProviderForm',
  mixins: [formMixin],

  data: () => {
    return {
      DEBUG: DEBUG, // TODO: Global or in Helper

      // DATA
      form: { id: 0, available: [] },

      // Extra
      lists: {},

      // Helpers
      requires_bank: false,
      validators: {},
      current_crud: {},
      showModalCRUD: false,
      original_lists: {}
    }
  },
  created() {
    this.form.id = this.$route.params.id || 0
    this.getData()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    getData() {
      const self = this
      self.$http
        .get('admin/providers' + (self.form.id ? `/${self.form.id}/edit` : '/create'), { _lists: 'provider_sectors,provider_payment_methods,account_types,banks' })
        .then(response => {
          self.parseData(response.data)
        })
        .catch(error => {
          console.error(error)
          self.showAlert(`There was an error.`, 'danger')
          //self.goBack() // TODO: Alert
          //self.$router.push({ path: 'login' })
        })
    },
    onAddElement(type) {
      this.current_crud = {
        url: `admin/${type}s`, // Pluralize
        name: type,
        type: `${type}s`, // Pluralize
        field: `${type}_id`,
        title: this._humanize(type),
        modal: true,
        fiels: ['name']
      }
      this.showModalCRUD = true
    },
    onModalUpdated(items) {
      this.lists[this.current_crud.type] = items
      // Force refresh list
      const value = this.form[this.current_crud.field]
      this.form[this.current_crud.field] = ''
      this.$nextTick(() => {
        this.form[this.current_crud.field] = value
      }, 100)
    },

    onPaymentMethodChanged(provider_payment_method) {
      this.requires_bank = false
      this.form.bank_id = ''
      this.form.account_number = ''
      this.form.account_type_id = ''
      if (provider_payment_method) this.requires_bank = provider_payment_method.requires_bank
    },

    // Parse Extra and Related data
    parseData(data) {
      this.form = data.provider ? data.provider : { id: 0, available: [] }
      this.is_principal = !!this.form.is_principal
      if (!Array.isArray(data._lists)) this.lists = data._lists || {}

      this.parseView()

      this.validators = {
        main: data._validation || {}
        //extra: data._extra_validation || {}
      }

      if (_.isEmpty(this.$v.$form)) this.parseValidator(data._validation, data._messages, true)

      //if (this.form.id) this.forcedSteps()
    },

    // Parsing

    parseView() {
      const options = [
        { click: this.submit, class: 'mr-2 btn-' + (this.form.id ? 'info' : 'primary'), content: this.$t(this.form.id ? 'Save' : 'Create') }, //disabled: this.isFormValid },
        { click: this.goBack, class: 'btn-secondary', content: this.$t('Back') }
      ]
      this.$store.dispatch('setTopActions', { [(this.form.id ? 'Edit' : 'Create') + 'CommonArea']: options })
    },

    // SUBMIT

    submit() {
      //console.log(this.form)

      this.$v.form.$touch()
      if (this.$v.form.$error) return

      const self = this
      self.$http[self.form.id ? 'put' : 'post']('admin/providers', self.form) // TODO: on service ?
        .then(response => {
          self.$router.replace(`/admin/tools/providers/${response.data.provider.id}/edit`).catch(() => {})
          self.showAlert(`Provider ${self.form.id ? 'saved' : 'created'}.`)
          self.parseData(response.data)
        })
        .catch(error => {
          // TODO: move to form helper ?
          //if (error && error.status === 422) {
          if (error.response && error.response.status === 422) {
            console.log('parseInputErrors')
            self.setErrors(error.response.data.errors || {})
          }
          if (error.response?.data?.message == 'The given data was invalid.') {
            for (const key in error.response.data.errors) {
              if (error.response.data.errors[key]) {
                self.message += error.response.data.errors[key][0] + '  '
              }
            }
          } else {
            console.error(error)
            self.showAlert(`There was an error ${self.form.id ? 'saving' : 'creating'} the provider.`, 'danger')
            //self.goBack() // TODO: login ?
          }
        })
    }
  }
}
</script>
